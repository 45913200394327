'use strict'

###*
 # @ngdoc object
 # @name mundoComponents.controller:MundoConfirmBoxCtrl

 # @description

###
class MundoConfirmBoxCtrl
  ### @ngInject ###
  constructor: (
    title,
    message,
    $mdDialog
  ) ->
    @ctrlName = 'MundoConfirmBoxCtrl'
    @title = title
    @message = message

    @cancel = ->
      $mdDialog.cancel()

    @submit = ->
      $mdDialog.hide()

angular
  .module('mundoComponents')
  .controller 'MundoConfirmBoxCtrl', MundoConfirmBoxCtrl
